/*** IMPORTS  ****/
import  React , { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import { Link } from "gatsby"
import BlockContent from '../components/block-content'
import BackgroundImage from 'gatsby-background-image'
import UspSection from "../components/uspSection"
import { FaPrint, FaStar, FaMapMarkerAlt, FaSink, FaUserShield, FaFaucet, FaToilet, FaRegClock, FaShieldAlt, FaPhone, FaSitemap } from "react-icons/fa";
import Form from "../components/form"
import PortableText from '@sanity/block-content-to-react'
import Image from "gatsby-image"
import $ from 'jquery'
import ourservicesBackground from "../images/ourServicesSide.jpg"
import CTA from "../components/cta"

function getUrlVars(){
    var vars = [], hash;
    if(typeof window !== 'undefined'){
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
    }
    return vars;
  }
  var city = getUrlVars()["city"];

  if (city !== undefined){
    let cityDash = city;
    cityDash = cityDash.replace(/-/g, ' ');

      var cityplace = " in " + cityDash;
      var citytitle = cityDash+"'s";
  } 
  function getUrlParams() {
    if (typeof window !== 'undefined') {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      return params;
    }
    return {};
  }
  
  const urlParams = getUrlParams();


/**** GRAPHQL QUERY *****/
export const query = graphql`
    query ourservicespageQuery {
        sanityPages(slug: {current: {eq: "our-services"}}) {
            pagetitle
            heroheader
            _rawHerocopy
            slug {
                current
            }
            usp1{
                uspTitle
                uspText
                icon
            }
            usp2{
                uspTitle
                uspText
                icon
            }
            usp3{
                uspTitle
                uspText
                icon
            }
            servicesIcon1{
                servicesTitle
                servicesLink
                servicesIcon{
                    asset {
                        fluid(maxWidth: 1920) {
                            ...GatsbySanityImageFluid
                        }
                    }
                }
            }
            servicesIcon2{
                servicesTitle
                servicesLink
                servicesIcon{
                    asset {
                        fluid(maxWidth: 1920) {
                            ...GatsbySanityImageFluid
                        }
                    }
                }
            }
            servicesIcon3{
                servicesTitle
                servicesLink
                servicesIcon{
                    asset {
                        fluid(maxWidth: 1920) {
                            ...GatsbySanityImageFluid
                        }
                    }
                }
            }
            servicesIcon4{
                servicesTitle
                servicesLink
                servicesIcon{
                    asset {
                        fluid(maxWidth: 1920) {
                            ...GatsbySanityImageFluid
                        }
                    }
                }
            }
            _rawFirstcopy(resolveReferences: { maxDepth: 10 })
            firstcopy {
            sanityChildren {
                marks
                text
                _type
                _key
            }
            list
            _type
            _key
            style
            }
            
            _rawServices
            coupon {
                title
                type
            }
            heroimage {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
            serviceimage {
                asset {
                    fluid(maxWidth: 1920) {
                      ...GatsbySanityImageFluid
                    }
                }
            }
        }
        sanityCompanyInfo {
            companyname
            companyTagline
            logoWhite{
                asset {
                    fluid{
                      ...GatsbySanityImageFluid
                      src
                    }
                  }
            }            
            ourservicesimage{
                asset {
                    fluid{
                      ...GatsbySanityImageFluid
                      src
                    }
                  }
            }
            primarycolor{
                hex
                rgb{
                    a
                    r
                    g
                    b
                }
            }
            secondarycolor{
                hex
            }
            accentcolor{
                hex
            }
            tertiarycolor{
              hex
            }
            tertiaryaltcolor{
              hex
            }
        }
        allSanitySocial{
          edges{
            node{
              socialproofTitle
              socialproofNumber
              }
            }
          }
    }
`

/***** GET CURRENT DAY *****/
const now = new Date();
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const today = days[now.getDay()];


/***** FUNCTION TO PRINT OUT COUPONS *****/
function printCoupon() {
    if(typeof window !== 'undefined'){
        window.print();
    }
}
/***** FUNCTION TO GET THE CITY PARAMETER FROM URL *****/

 


/***** ADD CITY TO URLS IN PAGE *****/
// function addCity(){
//     if(typeof window !== 'undefined' && city !== undefined){
//         $('div.pageContent a').attr('href', function(i, href){
//             city= getUrlVars()['city'];
//             return href + "?city=" +  city; 
//         });
//     }  
// }


/***** FUNCTION FOR BLOCK CONTENT LINKS *****/
const serializers = {
    marks: {
        internalLink: ({mark, children}) => {
            const {slug = {}} = mark
            const href = `/{slug.current}`
            return <Link to={href}>{children}</Link>
          }
      }
  }

  function changeActive(){
    $(".form").toggleClass("expanded");
    $('body').toggleClass('formExpanded');
  }


export default ({ data }) => {

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const urlParams = Object.fromEntries(urlSearchParams.entries());
    
      
        // Update the links with URL parameters
        document.querySelectorAll('.serviceLink').forEach((link) => {
          const serviceSlug = link.getAttribute('data-service-slug');
          const modifiedLink = `${serviceSlug}?${Object.entries(urlParams).map(([key, value]) => `${key}=${value}`).join('&')}`;
          link.href = modifiedLink;
        });
       
      }, []);
    
      return (
    <Layout>
        <Helmet>
            <title>{data.sanityCompanyInfo.companyname} | {data.sanityPages.pagetitle}</title>
        </Helmet>
        <div className="popupForm"><Form /></div>


        <div className="row heroRow">
            <div className="leftSection">
                <div className="leftSectionText">
                    <p className="tagline_marker" style={{color: data.sanityCompanyInfo.tertiarycolor.hex, textTransform: 'uppercase'}}><FaMapMarkerAlt/> {data.sanityCompanyInfo.companyTagline} {cityplace}</p>
                    <span className="heroBlockTitle"><h2>{data.sanityPages.heroheader} {cityplace}</h2></span>
                    <PortableText blocks={data.sanityPages._rawHerocopy} />
                    <a href="#" onClick={changeActive} 
                    style={{ backgroundColor: data.sanityCompanyInfo.accentcolor.hex, borderColor: data.sanityCompanyInfo.accentcolor.hex }}
                    > Schedule Service Today</a>
                </div>

                <div className="serviceIcon" style={{backgroundColor: data.sanityCompanyInfo.primarycolor.hex}}>
                    <div className="serviceIconInner">
                        <div className="serviceIconMain">
                        <div className="Icons">
                                <a href={data.sanityPages.servicesIcon1.servicesLink} className="serviceLink" data-service-slug={`${data.sanityPages.servicesIcon1.servicesLink}`}>
                                    <Image location=""
                                        fluid={data.sanityPages.servicesIcon1.servicesIcon.asset.fluid}
                                        style={{ height: "auto", width: "100%" }}
                                        className="align-center"
                                        alt="Service Icon 01"
                                    />
                                    <p style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.servicesIcon1.servicesTitle}</p>
                                </a>
                            </div>
                            <div className="Icons">
                                <a href={data.sanityPages.servicesIcon2.servicesLink} className="serviceLink" data-service-slug={`${data.sanityPages.servicesIcon2.servicesLink}`}>
                                    <Image location=""
                                        fluid={data.sanityPages.servicesIcon2.servicesIcon.asset.fluid}
                                        style={{ height: "auto", width: "100%" }}
                                        className="align-center"
                                        alt="Service Icon 02"
                                    />
                                    <p style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.servicesIcon2.servicesTitle}</p>
                                </a>
                            </div>
                            <div className="Icons">
                              <a href={data.sanityPages.servicesIcon3.servicesLink} className="serviceLink" data-service-slug={`${data.sanityPages.servicesIcon3.servicesLink}`}>
                                    <Image location=""
                                        fluid={data.sanityPages.servicesIcon3.servicesIcon.asset.fluid}
                                        style={{ height: "auto", width: "100%" }}
                                        className="align-center"
                                        alt="Service Icon 03"
                                    />
                                    <p style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.servicesIcon3.servicesTitle}</p>
                                </a>
                            </div>
                            <div className="Icons">
                                <a href={data.sanityPages.servicesIcon4.servicesLink} className="serviceLink" data-service-slug={`${data.sanityPages.servicesIcon4.servicesLink}`}>
                                    <Image location=""
                                        fluid={data.sanityPages.servicesIcon4.servicesIcon.asset.fluid}
                                        style={{ height: "auto", width: "100%" }}
                                        className="align-center"
                                        alt="Service Icon 04"
                                    />
                                    <p style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.servicesIcon4.servicesTitle}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rightSection">
                <BackgroundImage 
                style={{height: "100%", backgroundSize: "cover"}}
                fluid={data.sanityPages.heroimage.asset.fluid}>
                </BackgroundImage>
            </div>
        </div>

        <div className="coupon_section" style={{backgroundColor: data.sanityCompanyInfo.secondarycolor.hex}}>
            <div className="container">
                <div className="couponMain">
                <div className="coupon_text">
                    <p className="mobileTitle" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>Take Advantage of our latest offer</p>
                    <p className="coupon" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                    {data.sanityPages.coupon.title} <br className="coupon_break"/> <span className="mobileCouponType">{data.sanityPages.coupon.type}</span></p>
                    <p className="coupon_disclaimer_mobile" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>*Call office for more details.</p>
                    <a href="#" style={{backgroundColor: data.sanityCompanyInfo.primarycolor.hex}} onClick={printCoupon}>Claim Offer</a>
                </div>
                </div>
                <div className="couponClaim">
                    <p className="coupon_disclaimer" style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>*Call office for more details.</p>
                </div>
            </div>
        </div>

        <div className="ourServicesPage">
            <div className="row firstCopy">
                <div className="leftImage" style={{ 
                    backgroundImage: `url(${data.sanityCompanyInfo.ourservicesimage.asset.fluid.src})`, 
                    position: 'relative',
                    overflow: 'hidden'
                }}>
                <div style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%', 
                    backgroundColor: `rgba(${data.sanityCompanyInfo.primarycolor.rgb.r},${data.sanityCompanyInfo.primarycolor.rgb.g},${data.sanityCompanyInfo.primarycolor.rgb.b},0.7)`
                }}>
                </div>
                </div>
                <div className="pageContent" style={{backgroundColor: data.sanityCompanyInfo.tertiarycolor.hex, color:"#fff" }}>
                    <div className="container">
                     <PortableText blocks={data.sanityPages._rawFirstcopy} serializers={serializers} />
                     <br/>
                    <a href="#" onClick={changeActive} 
                    style={{ color: data.sanityCompanyInfo.tertiaryaltcolor.hex, backgroundColor: data.sanityCompanyInfo.primarycolor.hex, borderColor: data.sanityCompanyInfo.primarycolor.hex }}
                    > Schedule Service Today</a>
                     </div>
                </div>
            </div>
            <div className="socialProof" style={{backgroundColor: data.sanityCompanyInfo.secondarycolor.hex, color:"#fff" }}>
                <div className="container">
                    <div className="socialBlurb">
                        {data.allSanitySocial.edges.map(({ node: social }) => (
                    <div className="blurp">
                        <div className="socialInfo">
                        <h3>{social.socialproofNumber}</h3>
                        <p>{social.socialproofTitle}</p>
                        </div>
                    </div>
                        ))}
                    </div>
                </div>
            </div>   
            <div className="row servicesRow">
                <div className="leftSection">
                    <BackgroundImage
                        style={{ height: "100%" }}
                        fluid={data.sanityPages.serviceimage.asset.fluid}>
                    </BackgroundImage>
                </div>
                <div className="rightSection" style={{ backgroundColor: data.sanityCompanyInfo.primarycolor.hex }}>
                    <span className="rightSectionTitle"><h2 style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>Why You Should Choose {data.sanityCompanyInfo.companyname}</h2></span>
                    <span className="rightSectionText">
                        <p style={{color: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                        <BlockContent blocks={data.sanityPages._rawServices} /></p>
                    </span>
                    <div className="ourServiceUSP">
                        <div className="three-columns">
                            <div className="column column1">
                                <i className={"fa fa-" + data.sanityPages.usp1.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                                <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp1.uspTitle}</h2>
                            </div>
                            <div className="column column2">
                                <i className={"fa fa-" + data.sanityPages.usp2.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                                <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp2.uspTitle}</h2>
                            </div>
                            <div className="column column3">
                                <i className={"fa fa-" + data.sanityPages.usp3.icon} style={{ fontSize: '4em', color: data.sanityCompanyInfo.tertiarycolor.hex }}/>
                                <h2 style={{ color: data.sanityCompanyInfo.tertiarycolor.hex }}>{data.sanityPages.usp3.uspTitle}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>       
        <CTA />
    </Layout>
)}
